
.banner_child {
  .native_banner {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    text-align: center;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: center;
    -webkit-justify-content: center;
  }
}
.leagues {
  margin-top: 16px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 12px;
  .nopointer {
    cursor: auto;
  }
  .project-pitcher-modal {
    div {
      .modal-content {
        @media all and (max-width: 1024px) {
          padding: 0px;
        }
        .heading {
          display: flex;
          display: -webkit-flex;
          align-items: center;
          -webkit-align-items: center;
          justify-content: space-between;
          -webkit-justify-content: space-between;
          padding: 15px 15px;
          border-bottom: 0.5px solid #e0e0e0;
          h1 {
            color: #000;
            font-size: 20px;
            font-weight: 600;
          }
          button {
            line-height: 0px;
            font-size: 30px;
            opacity: 0.5;
            font-weight: 500;
          }
        }
        .modal-body {
          padding: 15px;
          table {
            thead {
              tr {
                th {
                  width: 50%;
                  text-align: left;
                }
              }
            }
            tbody {
              tr {
                td {
                  text-align: left;
                  @media all and (max-width: 360px) {
                    text-align: left !important;
                    &::before {
                      position: relative;
                      top: 0;
                      display: block;
                      left: 0;
                      text-align: left;
                      margin-bottom: 5px;
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
        .modal-footer {
          padding: 0px 15px 15px;
          display: flex;
          display: -webkit-flex;
          justify-content: flex-end;
          -webkit-justify-content: flex-end;
          button {
            padding: 10px 15px;
            background: #6c757d;
            color: #fff;
            border-radius: 2px;
            font-size: 16px;
          }
        }
      }
    }
  }
  .league-group:last-child table.time-group:last-child {
    margin-bottom: 0 !important;
  }
  .notfound {
    font-weight: bold;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .league-group {
    .time-remain {
      font-size: 16px;
      text-align: right;
      font-weight: 500;
      margin-bottom: 12px;
      span {
        background: #fff;
        color: #fa8c28;
        padding: 6px 10px;
        display: inline-block;
        border-radius: 5px;
        border: 1px solid #fa8c28;
        text-align: center;
        margin-bottom: 8px;
      }
    }
    p.date {
      padding: 12px 0;
      margin: 0;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      color: #ffffff;
      background: #2e305c;
      border-radius: 4px;
      margin-bottom: 8px;
      @media all and (max-width: 959px) {
        font-size: 15px !important;
      }
    }
    table.time-group {
      background: #ffffff;
      border-spacing: 0;
      border-collapse: separate;
      width: 100%;
      margin-bottom: 8px;

      &.desktoponly {
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      tr th,
      tr td {
        border-right: 0.5px solid #e0e0e0;
        border-bottom: 0.5px solid #e0e0e0;
        &:first-child {
          border-left: 0.5px solid #e0e0e0;
        }
      }
      tr th {
        background: #eee;
        border-top: 0.5px solid #e0e0e0;
        text-align: left;
      }

      /* top-left border-radius */
      tr:first-child th:first-child {
        border-top-left-radius: 4px;
      }

      /* top-right border-radius */
      tr:first-child th:last-child {
        border-top-right-radius: 4px;
      }

      /* bottom-left border-radius */
      tr:last-child td:first-child {
        border-bottom-left-radius: 4px;
      }

      /* bottom-right border-radius */
      tr:last-child td:last-child {
        border-bottom-right-radius: 4px;
      }
      thead {
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        color: #000000;
        background: #f5f5f5;
        .nobk {
          background-color: white;
          border-left: 0px;
          border-right: 0px;
          .time-remain {
            margin-bottom: 0;
          }
        }
        tr th {
          padding: 7px 8px !important;
          text-align: center;
          font-size: 15px !important;

          @media screen and (max-width: 767px) {
            font-size: 15px !important;
            font-weight: 500 !important;
          }
          @media screen and (min-width: 960px) and (max-width: 1299px) {
            font-size: 15px !important;
          }
          &.time {
            text-align: left;
            padding-left: 12px;
          }
        }
      }
      tbody {
        tr {
          text-transform: uppercase;
          font-style: normal;
          font-size: 9px;
          line-height: 9px;
          cursor: pointer;
          border-bottom: #2e305c solid 2px;
          td {
            font-size: 15px;
            font-weight: 400;
            padding: 10px 8px !important;
            line-height: 16px;
            @media screen and (min-width: 960px) and (max-width: 1090px) {
              font-size: 14px !important;
            }
            @media screen and (max-width: 959px) {
              font-size: 15px !important;
            }
            @media screen and (max-width: 767px) {
              font-size: 15px !important;
              font-weight: 500 !important;
            }
            &.time {
              padding: 11px 0 11px 12px;
              color: #000000;
              .pitcher {
                display: inline-block;
                cursor: pointer;
                span {
                  color: #51c543;
                  font-weight: 700;
                }
              }
            }
            &:not(.time) {
              // font-weight: 400;
              text-align: center;
              color: #000000;
            }
            .text-with-arrow {
              display: flex;
              align-items: center;
              display: -webkit-flex;
              -webkit-align-items: center;
              .inset {
                width: 100%;
                margin: 0 auto;
                display: flex;
                display: -webkit-flex;
                justify-content: center;
                -webkit-justify-content: center;
                align-items: center;
                -webkit-align-items: center;
                span {
                  width: 12px;
                  line-height: 0px;
                  img {
                    max-width: 100%;
                  }
                }
                p {
                  width: 100px;
                  @media screen and (min-width: 960px) and (max-width: 1090px) {
                    font-size: 14px !important;
                    width: 87px;
                  }
                  @media all and (max-width: 959px) {
                    font-size: 15px !important;
                  }
                  @media all and (max-width: 767px) {
                    width: auto;
                    margin-left: 10px;
                  }
                }
                @media all and (max-width: 767px) {
                  justify-content: flex-end;
                  -webkit-justify-content: flex-end;
                }
              }
            }
          }
          td:nth-child(1) {
            width: 40%;
            @media screen and (max-width: 767px) {
              width: 100%;
            }
          }
          td:nth-child(2),
          td:nth-child(3),
          td:nth-child(4) {
            width: 20%;
            @media screen and (max-width: 767px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .modal-content {
    .body {
      justify-content: center;
      -webkit-justify-content: center;
      .item {
        width: 25%;
        p {
          @media all and (max-width: 959px) {
            font-size: 16px !important;
          }
        }
        @media all and (max-width: 768px) {
          width: 50%;
        }
      }
      &.oops_mode {
        font-size: 16px;
        font-weight: 500;
        color: #2e305c;
        max-width: 523px;
        flex-wrap: wrap;
        margin: 0 auto;
        -webkit-flex-wrap: wrap;
        .image {
          max-width: 60px;

          img {
            max-width: 100%;
          }
        }
        .text-data {
          margin-top: 15px;
          line-height: 35px;
          font-size: 20px;
          text-align: center;
          width: 100%;
          &::v-deep span {
            background: #fe646f;
            padding: 5px 10px;
            border-radius: 3px;
            color: #fff;
            margin: 0 5px;
          }
        }
      }
    }
    @media all and (max-width: 1024px) {
      padding: 56px;
    }
    @media all and (max-width: 767px) {
      padding: 20px;
    }
  }

  .white-content {
    background: #fff;
    border: #e0e0e0 solid thin;
    padding: 15px;
    border-radius: 8px;
    margin-top: 15px;

    h1 {
      color: #000000;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      margin-bottom: 15px;
    }
    h2 {
      color: #000000;
      font-style: normal;
      font-weight: 700;
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 20px;
    }
    p {
      margin-bottom: 10px;
      color: #000000;
      margin-top: 10px;
      font-size: 15px !important;
      line-height: 26px !important;
    }
    ul {
      margin: 0px;
      padding: 0px;
      text-indent: 8px;

      li {
        list-style-position: inside;
        margin-bottom: 10px;
      }
    }
  }

  .sportbook-listing {
    .title-list {
      background: #ebebeb;
      color: #000;
      padding: 15px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 600;
    }
    .sportlist {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 10px;
      border-bottom: #e0e0e0 solid thin;

      &:first-child {
        border-top: #e0e0e0 solid thin;
      }

      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
      }
      @media screen and (min-width: 1025px) and (max-width: 1400px) {
        flex-wrap: wrap;
      }

      .leftbook {
        flex: 0 0 60%;
        flex-shrink: 1;
        flex-direction: row;
        display: flex;
        align-items: center;
        margin-right: 10px;

        @media screen and (max-width: 767px) {
          flex: 0 0 100%;
        }
        @media screen and (min-width: 1025px) and (max-width: 1400px) {
          flex: 0 0 100%;
        }

        .imgpart {
          width: 50px;
          min-width: 50px;
          height: 50px;
          overflow: hidden;
          border-radius: 6px;
          margin-right: 15px;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top center;
          }
        }

        .contpart {
          width: calc(100% - 50px);
          span.subhed {
            font-size: 14px;
            font-weight: 500;
            color: #000000;
            @media screen and (max-width: 767px) {
              font-size: 14px !important;
            }
          }
          p.subcont {
            font-size: 16px;
            font-weight: 500;
            color: #000000;

            @media screen and (max-width: 767px) {
              font-size: 14px !important;
            }
          }
        }
      }

      .rightbook {
        flex: 0 0 40%;
        flex-shrink: 1;

        @media screen and (max-width: 767px) {
          flex: 0 0 100%;
        }
        @media screen and (min-width: 1025px) and (max-width: 1400px) {
          flex: 0 0 100%;
        }

        .offerbtn {
          display: flex;
          margin-top: 15px;
          gap: 5px;

          a {
            text-decoration: none;
            flex: 0 0 50%;
            flex-shrink: 1;
            flex-direction: column;
            display: flex;
            justify-content: center;
            background: #ebebeb;
            padding: 8px 12px;
            text-align: center;
            border-radius: 5px;
            margin-right: 10px;

            .codepromo {
              color: #000;
              font-size: 15px;
              text-transform: uppercase;
              font-weight: 600;
              @media screen and (min-width: 1026px) and (max-width: 1299px) {
                font-size: 12px;
              }
            }
            .codetext {
              font-size: 10px;
              color: #000;
            }
          }

          .claimoffer {
            text-decoration: none;
            flex: 0 0 50%;
            flex-shrink: 1;
            background: #fa8c28;
            color: #ffffff;
            padding: 12px;
            font-size: 15px;
            font-weight: 600;
            border-radius: 5px;
            @media screen and (min-width: 1026px) and (max-width: 1299px) {
              font-size: 12px;
            }
          }
        }
      }
    }
    .no-bonus {
      text-align: center;
      padding-bottom: 15px;
      padding-top: 15px;
    }

    .sportlist + .sportlist {
      border-bottom: #e0e0e0 solid thin;
    }
  }
  .nativebanner {
    width: 100%;
    margin-bottom: 10px;
  }
}

// formobileonly table
@media screen and (max-width: 767px) {
  table.time-group.formobileonly {
    display: block;
    margin-bottom: 0px !important;

    thead {
      display: none;
    }
    tbody {
      display: block;
      width: 100%;

      tr {
        display: block;
        width: 100%;

        td {
          display: block;
          width: 100%;
          text-align: right !important;
          position: relative;
          border: #e0e0e0 solid thin;
          border-bottom: none;
          font-size: 15px !important;
          padding: 14px !important;

          &:first-child {
            background: #f1f1f1;
          }

          &::before {
            content: " " attr(title) "";
            position: absolute;
            left: 11px;
            font-size: 15px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
